import { FC, useEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import { Modal } from "rsuite";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import "cropperjs/dist/cropper.css";
import { CustomFileInput } from "./CustomFileInput";
import RangeZoomInput from "./RangeZoomInput";
import { ALLOWED_FILE_EXTENSIONS, MAX_FILE_SIZE } from "../../../appConsts";
import { validateFileType } from "../../methods/validateFileType";
import classNames from "classnames";
import { toast } from "react-toastify";

type CropImageModalProps = {
  isOpen: boolean;
  isSquareImg?: boolean;
  onClose: () => void;
  onSubmit: (v: string) => void;
  isAvatarCrop?: boolean;
  aspectRatio?: number;
  editableImage?: {
    fileUrl: string;
    file: Blob | null;
  } | null;
};

type CropValues = {
  picture: Blob | File | null;
};

const initialCropValues: CropValues = {
  picture: null,
};

const validFileExtensions = ALLOWED_FILE_EXTENSIONS;

// reference https://shinerweb.com/how-to-crop-image-in-round-shape-by-using-cropper-js/
function getRoundedCanvas(sourceCanvas: HTMLCanvasElement) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const width = sourceCanvas.width;
  const height = sourceCanvas.height;
  canvas.width = width;
  canvas.height = height;
  if (context) {
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
  }

  return canvas;
}

export const CropImageModal: FC<CropImageModalProps> = ({
  isOpen,
  isSquareImg,
  onClose,
  onSubmit,
  isAvatarCrop,
  aspectRatio,
  editableImage,
}) => {
  const { t } = useTranslation("common");
  const cropperRef = useRef<ReactCropperElement | null>(null);
  const formikRef = useRef<FormikProps<CropValues>>(null);
  const [initialValues, setInitialValues] = useState<CropValues | null>(null);

  const [isCropperActive, setIsCropperActive] = useState(false);
  const [minZoomValue, setMinZoomValue] = useState(0);

  useEffect(() => {
    if (!isOpen) return;

    if (editableImage) {
      setInitialValues({
        picture: editableImage.file,
      });
      setIsCropperActive(true);
    } else {
      setInitialValues(initialCropValues);
    }
  }, [isOpen, editableImage]);

  const ValidationSchema = Yup.object().shape({
    picture: Yup.mixed()
      .nullable()
      .required(t("validation_fieldIsRequired") as string)
      .test("is-valid-type", t("unsupportedImageFormats"), (value) => {
        return value && value.name
          ? validateFileType(value.name.toLowerCase(), validFileExtensions)
          : true;
      })
      .test("is-valid-size", t("maxAllowedImgSizeErr"), (value) =>
        value && value.size ? value.size <= MAX_FILE_SIZE : true
      ),
  });

  const initializeZoom = () => {
    const imageData = cropperRef.current?.cropper.getImageData();
    if (imageData) {
      const minSliderZoom = imageData.width / imageData.naturalWidth;
      setMinZoomValue(minSliderZoom);
    }
  };

  // this function used for initialize crop box size for get banner images
  const initializeCropBox = () => {
    const cropBoxData = cropperRef.current?.cropper.getCropBoxData();

    // according to design banners have 300px height
    const cropBoxHeight = 300;

    if (cropBoxData && !aspectRatio) {
      cropperRef.current?.cropper.setCropBoxData({
        ...cropBoxData,
        height: cropBoxHeight,
      });
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    formikRef.current?.setErrors({});
    setIsCropperActive(false);

    setInitialValues(initialCropValues);
    onClose();
  };

  const handleSubmit = (values: CropValues) => {
    if (!isCropperActive) {
      onSubmit(URL.createObjectURL(values.picture as File));
      handleClose();

      return;
    }

    const cropper = cropperRef.current?.cropper;
    let cropData = null;

    const croppedCanvas = cropper ? cropper.getCroppedCanvas() : null;

    if (croppedCanvas) {
      if (isAvatarCrop) {
        // Round
        const roundedCanvas = getRoundedCanvas(croppedCanvas);

        cropData = roundedCanvas.toDataURL();
      } else {
        cropData = croppedCanvas.toDataURL();
      }

      if (cropData) {
        onSubmit(cropData);

        handleClose();
      }
    } else {
      toast.error(t("cropImgErr"));
    }
  };

  if (!initialValues) return null;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}} // we submit form (crop Image and save) by click on Submit button due to strange behavior of getCroppedCanvas() func, it doesn't crop image on submit, only onClick
      validationSchema={ValidationSchema}
      innerRef={formikRef}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {(props: FormikProps<CropValues>) => {
        const { values, errors, handleBlur, touched } = props;

        return (
          <Modal
            size="lg"
            open={isOpen}
            onClose={handleClose}
            className={classNames("cropImageModal", {
              squareCrop: isSquareImg,
            })}
          >
            <Form className="form-style cropModal">
              <Modal.Header>
                <Modal.Title className="cropTitle">
                  {t(isCropperActive ? "cropImage" : "uploadAndEditImageModal")}
                </Modal.Title>

                {!isCropperActive && (
                  <div className="imageRequirements">
                    <p>{t("imageRequirements")}</p>
                    <p>
                      {t(
                        isSquareImg
                          ? "preferredSizesSquare"
                          : "preferredSizesRect"
                      )}
                    </p>
                  </div>
                )}
              </Modal.Header>

              <Modal.Body className="cropImageModal__body">
                <div className="divider" />

                {!values.picture && (
                  <Field
                    name="picture"
                    component={CustomFileInput}
                    errorMessage={errors["picture"] ? errors["picture"] : null}
                    touched={touched["picture"]}
                    onBlur={handleBlur}
                  />
                )}

                {values.picture && !errors.picture && (
                  <>
                    {isCropperActive ? (
                      <div className="cropperWrap">
                        <Cropper
                          ref={cropperRef}
                          preview=".img-preview"
                          src={URL.createObjectURL(values.picture)}
                          background={false}
                          responsive={true}
                          className={isAvatarCrop ? "avatarCropper" : "cropper"}
                          cropBoxResizable={false}
                          dragMode={"move"}
                          toggleDragModeOnDblclick={false}
                          guides={false}
                          autoCropArea={1}
                          center={false}
                          aspectRatio={aspectRatio}
                          movable={false}
                          viewMode={1}
                          zoomOnTouch={false}
                          zoomOnWheel={false}
                          ready={() => {
                            initializeZoom();
                            initializeCropBox();
                          }}
                        />

                        <RangeZoomInput
                          picture={values.picture}
                          handleChangeZoom={(ratio: number) =>
                            cropperRef.current?.cropper.zoomTo(ratio)
                          }
                          minZoomValue={minZoomValue}
                        />
                      </div>
                    ) : (
                      <div className="uploadedImageWrap">
                        <img
                          src={URL.createObjectURL(values.picture as File)}
                          className="uploadedImageWrap__img"
                        />

                        <div className="d-flex">
                          <Field
                            name="picture"
                            component={CustomFileInput}
                            errorMessage={
                              errors["picture"] ? errors["picture"] : null
                            }
                            touched={touched["picture"]}
                            onBlur={handleBlur}
                          />

                          <button
                            type="button"
                            onClick={() => setIsCropperActive(true)}
                            className="btn-regular cropImageBtn"
                          >
                            {t("cropImage")}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {!isCropperActive && <div className="divider bottom" />}
              </Modal.Body>

              <Modal.Footer>
                <div
                  className={classNames("d-flex cropModal__footer", {
                    cropModal__footerCentered: !values.picture,
                  })}
                >
                  <button
                    type="button"
                    onClick={handleClose}
                    className="btn-regular"
                  >
                    {t("form_cancel")}
                  </button>

                  <button
                    type="submit"
                    onClick={() => handleSubmit(values)}
                    className={!values.picture ? "btn-regular" : "btn-primary"}
                    disabled={!values.picture}
                  >
                    {t("form_save")}
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CropImageModal;
