import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  AvailablePromotedMerchRequest,
  AvailablePromotedMerchResponse,
  CreateMerchOrderRequestBody,
  CreateMerchResponse,
  MerchCategoryListResponse,
  MerchItemDto,
  MerchItemOrdersODataDto,
} from "./types/merchStoreTypes";

export const loadMerchCategoryList =
  (): AxiosPromise<MerchCategoryListResponse> =>
    axiosInstance.get(`${base_url}/api/merch`);

export const loadMerchItem = (id: number): AxiosPromise<MerchItemDto> =>
  axiosInstance.get(`${base_url}/api/merch/${id}`);

export const loadMerchItemExchangesCount = (
  merchId: number
): AxiosPromise<MerchItemOrdersODataDto> =>
  axiosInstance.get(
    `${base_url}/api/merch-orders/odata?$filter=MerchItemId eq ${merchId} and Status ne 'Cancelled'&$count=true&$top=0`
  );

export const makeOrder = (
  id: number,
  body: CreateMerchOrderRequestBody
): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/merch/${id}/order`, body);
export const deleteMerch = (id: number): AxiosPromise =>
  axiosInstance.delete(`${base_url}/api/merch/${id}`);

export const addMerch = (body: FormData): AxiosPromise<CreateMerchResponse> =>
  axiosInstance.post(`${base_url}/api/merch`, body);

export const editMerch = (body: FormData, merchId: number): AxiosPromise =>
  axiosInstance.put(`${base_url}/api/merch/${merchId}`, body);

export const loadMerchPhoto = (merchPhotoUrl: string) =>
  axiosInstance.get(`${base_url}/api/storage/blob?url=${merchPhotoUrl}`, {
    responseType: "blob",
  });

export const loadAvailablePromotedMerch = (
  body: AvailablePromotedMerchRequest
): AxiosPromise<AvailablePromotedMerchResponse> =>
  axiosInstance.post(`${base_url}/api/merch/promoted`, body);
