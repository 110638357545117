import { dateOnly } from "utils/types";
import { ExportFormat, PageDto } from "./commonTypes";
import { MerchItemDto } from "./merchStoreTypes";
import { CustomerMerchDto } from "./usersTypes";

export enum OrderStatuses {
  Created = "Created",
  InProgress = "InProgress",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
}

export type MerchOrdersStatsResponse = {
  created: number;
  inProgress: number;
  delivered: number;
  cancelled: number;
};

export type MerchOrderDto = {
  id: number;
  user: CustomerMerchDto;
  merchItem: MerchItemDto;
  createdDate: string;
  coins: number;
  status: OrderStatuses | string;
  orderDetails: string | null;
  deliveryDate: dateOnly | null;
  merchDeliveryInfo: MerchDeliveryInfoDto | null;
};

export type MerchDeliveryInfoDto = {
  receiverFirstName: string;
  receiverFamilyName: string;
  receiverPhone: string;
  receiverCountry: string;
  receiverRegion: string | null;
  receiverCity: string;
  zipCode: string;
  deliveryPostalDepartment: string | null;
  deliveryAddress: string | null;
  deliveryTopSize: string;
  deliveryFootSize: string;
  deliveryBottomSize: string;
};

export type OrdersFilters = {
  status: OrderStatuses | null;
  dateRange: {
    dateFrom: Date | string;
    dateTo: Date | string;
  } | null;
  departaments: string[];
  employees: number[];
};

export type SearchOrdersRequest = {
  filters: OrdersFilters | null;
  pageNumber: number;
  pageSize: number;
};

export type SearchOrdersResponse = PageDto<MerchOrderDto>;

export type ExportOrdersDataRequest = {
  exportFormat: ExportFormat;
  filters: OrdersFilters | null;
};

export type OrderDetailsRequestBody = {
  orderDetails: string;
};

export type OrderDeliveryDateRequest = {
  deliveryDate: dateOnly;
};
