import { FC, useEffect, useState } from "react";
import { Slider } from "rsuite";
import { useTranslation } from "react-i18next";

type RangeZoomInputProps = {
  picture: File | Blob;
  handleChangeZoom: (v: number) => void;
  minZoomValue: number;
};

const RangeZoomInput: FC<RangeZoomInputProps> = ({
  picture,
  handleChangeZoom,
  minZoomValue,
}) => {
  const { t } = useTranslation("common");
  const [zoomValue, setZoomValue] = useState(0);

  useEffect(() => {
    setZoomValue(0);
  }, [picture]);

  return (
    <div className="zoomSlider">
      <p className="zoomSlider__title">{t("zoom")}</p>

      <Slider
        progress
        min={minZoomValue}
        max={minZoomValue + 4}
        step={0.01}
        className="zoomSlider__slider"
        value={zoomValue}
        onChange={(value) => {
          setZoomValue(value);
          handleChangeZoom(value);
        }}
        tooltip={false}
      />

      <div className="d-flex">
        <div>-</div>
        <div>+</div>
      </div>
    </div>
  );
};

export default RangeZoomInput;
