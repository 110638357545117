import { changeAvatar, changeUserCoverImg } from "api/employeePageApi";
import { UserProfile } from "api/types/usersTypes";
import { AxiosError } from "axios";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCropTitle } from "utils/methods/getCropTitle";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { refreshUserData } from "store/thunks/employeePage/employeePageThunks";
import { serverError } from "types/serverError";
import { CropImageModal, Icon } from "utils/components";
import { AppPermissions } from "utils/consts";
import { getAvatarSignFromUser, getFullNameFromUser } from "utils/methods";
import { toastError, toastSuccess } from "utils/toasts";
import { useAppDispatch } from "../../store";
import HasPermissions from "../../auth/HasPermissions";
import CoverImage from "../../utils/components/CoverImage";
import { useIsCurrentUser } from "../../utils/hooks/useIsCurrentUser";
import { getUserInfo } from "../../store/thunks/authThunks";
import { usePermissionCheck } from "../../utils/hooks";

type PlayerInfoBlockProps = {
  user: UserProfile;
};

const MainUserInfoBlock: FC<PlayerInfoBlockProps> = ({ user }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("employeePage");
  const [isCropAvatarModalOpen, setIsCropAvatarModalOpen] = useState(false);
  const isCurrentUser = useIsCurrentUser();
  const isAdmin = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
  ]);
  const isViewOrgStructure = usePermissionCheck([
    AppPermissions.ViewCompanyOrgStructure,
  ]);
  const isAccessToEditAvatar = isCurrentUser || isAdmin;
  const [validationCoverError, setValidationCoverError] = useState<
    null | string[]
  >(null);
  const saveAvatar = async (croppedImg: string) => {
    const result = await fetch(croppedImg);
    const blob = await result.blob();
    const formData: FormData = new FormData();
    formData.append("AvatarImage", blob, `userAvatar${user.id}.png`);

    try {
      await changeAvatar(user.id, formData);
      dispatch(refreshUserData(user.id));
      if (isCurrentUser) {
        dispatch(getUserInfo(user.id));
      }
      toastSuccess(t("avatarUpdated_toast"));
      setIsCropAvatarModalOpen(false);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || t("avatarUpdated_error"));
    }
  };

  const saveCoverImg = async (croppedImg: string) => {
    const result = await fetch(croppedImg);
    const blob = await result.blob();
    const formData: FormData = new FormData();
    formData.append("CoverImage", blob, `userCoverImg${user.id}.png`);

    try {
      await changeUserCoverImg(user.id, formData);
      dispatch(refreshUserData(user.id));
      toastSuccess(t("coverImgUpdated_toast"));
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      if (error.errors) {
        setValidationCoverError(error.errors?.coverImage);
      }
      toastError(error?.message || t("coverImgUpdated_error"));
    }
  };

  const handleRedirectToDirectory = () => {
    if (!isViewOrgStructure) return;

    const locationState = {
      userCountry: user.country,
    };

    navigate("/directory", { state: locationState });
  };

  return (
    <div className="employeeProfile">
      <CoverImage
        uploadedImgPreview={user.coverImageUrl || "/img/defaultCover.png"}
        saveUploadedImage={saveCoverImg}
        isEditBtn={isAccessToEditAvatar}
      />

      <div className="employeeProfile__userInfo">
        <h1 className="main-title">
          {getCropTitle(getFullNameFromUser(user), 30)}
        </h1>
        {user.position && <p className="secondary-title">{user.position}</p>}
        {user.country && (
          <HasPermissions
            requiredPermissions={[
              AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewLocation,
            ]}
          >
            <div
              className={classNames("employeeProfile__location", {
                "employeeProfile__location-active": isViewOrgStructure,
              })}
              onClick={handleRedirectToDirectory}
            >
              <Icon href="#ic-address" svgClass="ic-address" />
              <p className="secondary-title">{user.country}</p>
            </div>
          </HasPermissions>
        )}

        <div className="employeeProfile__avatar">
          {user.avatarImageUrl ? (
            <img
              src={user.avatarImageUrl}
              alt="avatar"
              className="employeeProfile__avatarImg"
            />
          ) : (
            <span className="employeeProfile__avatarName">
              {getAvatarSignFromUser(user)}
            </span>
          )}
          {isAccessToEditAvatar ? (
            <button
              type="button"
              className="employeeProfile__avatarBtn"
              onClick={() => setIsCropAvatarModalOpen(true)}
            >
              <Icon href="#edite" svgClass="employeeProfile__avatarIcon" />
              <p>{t("uploadAvatarBtn")}</p>
            </button>
          ) : null}
        </div>
      </div>

      {validationCoverError && (
        <div className="m-btm">
          {validationCoverError.map((error) => (
            <p key={error} className="error">
              {error}
            </p>
          ))}
        </div>
      )}

      <CropImageModal
        isOpen={isCropAvatarModalOpen}
        onClose={() => setIsCropAvatarModalOpen(false)}
        onSubmit={saveAvatar}
        isAvatarCrop={true}
        aspectRatio={1}
        isSquareImg
      />
    </div>
  );
};

export default MainUserInfoBlock;
