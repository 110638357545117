import {
  ActivityAnalyticsActiveUsersSortBy,
  ActivityAnalyticsPeopleOverTimeSortBy,
  ActivityInsightsDto,
  ActivityMostActiveUsersItemDto,
  PeopleOverTimeItemDto,
  PeopleOverTimeMainFilters,
} from "api/types/activityAnalyticsTypes";
import {
  CompanyCalendarDateItem,
  CompanyCalendarEventType,
  CompanyCalendarRecentAchievementDto,
  CompanyCalendarTeamDates,
} from "api/types/companyCalendarType";
import {
  GroupFilterType,
  UserGroupDto,
  UserGroupWithParticipantsDto,
} from "api/types/groupManagementTypes";
import { TimeOffCalendarUserListItemDto } from "api/types/timeOff/timeOffCalendar";
import {
  TimeOffPolicyAssignPeople,
  TimeOffPolicyAssignPeopleSortBy,
  TimeOffPolicyPositionsRequest,
  TimeOffPolicyUserDto,
} from "api/types/timeOff/timeOffPolicy";
import {
  TimeOffLocationFilter,
  TimeOffPolicyListItemDto,
  TimeOffPolicyTypeDto,
  TimeOffPolicyTypeListItemDto,
} from "api/types/timeOff/timeOffPolicyTypes";
import {
  TimeOffRequestsManagementListItemDto,
  TimeOffRequestsStatusFilter,
} from "api/types/timeOff/timeOffRequestsTypes";
import { UserDirectoryDto } from "api/types/usersDirectoryTypes";
import {
  CustomFilters,
  CustomSearchedFilters,
} from "utils/components/CustomFiltersForm";
import {
  AchievementCategorySearchDto,
  AchievementEventDetailedDto,
  AchievementEventDto,
  AchievementSearchDto,
  AchievementsEventsFilters,
  AchievementsFilterByCategory,
  AchievementsSortBy,
  AchievementsStatuses,
  DateSortDirectionAchievementsEvents,
} from "../api/types/achievementEventsTypes";
import {
  AchievementCategoryItemDto,
  AchievementDto,
  AchievementParticipantsTabs,
} from "../api/types/achievementTypes";
import {
  AchievementAnalyticsSortBy,
  AchievementMostActiveUsersItemDto,
  AchievementsInsightsDto,
  AchievementsParticipantsRatingItemDto,
  CategoryWithAchievementsDto,
  CategoryWithParticipantDto,
  EmployeeEngagementStatuses,
  PeopleEventsEngagementStatsDto,
  PeopleOverEventsItemDto,
  PeopleOverEventsMainFilters,
} from "../api/types/achievementsAnalyticsTypes";
import {
  AchievementArchiveItemDto,
  AchievementArchiveListDto,
  AchievementArchiveListFilters,
} from "../api/types/achievementsArchiveTypes";
import {
  AnalyticsDataResponse,
  AnalyticsPopularBadgeDto,
  AnalyticsRequest,
  FiltersDataResponse,
} from "../api/types/analyticTypes";
import { BadgeGroupManagementDto } from "../api/types/badgesManagementTypes";
import { ClientDateRange, SortDirections } from "../api/types/commonTypes";
import { Company, CompanyTreeItem } from "../api/types/companyTypes";
import { EmojiReactionUsersItemDto } from "../api/types/emojiTypes";
import {
  EmployeeOrdersSortBy,
  ExchangeHistoryItemDto,
  Feedback,
  TimeOffRequestListItemDto,
  UnresponsedFeedbackRequest,
  UserBadgeDto,
  UserCardDto,
  UserTimeOffBacklogSortBy,
  UserTimeOffBalanceListItemDto,
  UserTimeOffRequestDto,
} from "../api/types/employeePageTypes";
import { LeaderDto, SearchLeadersMode } from "../api/types/leaderboardTypes";
import { AutoCoinEventListItemDto } from "../api/types/manageAutoEventsTypes";
import {
  MerchOrderDto,
  MerchOrdersStatsResponse,
  OrdersFilters,
} from "../api/types/merchDeliveryTypes";
import {
  ItemOverTimeDto,
  ItemsOverTimeSortBy,
  MerchCoinStatisticsDto,
  PopularMerchItemDto,
  PopularMerchItemsTabs,
} from "../api/types/merchItemsManagementTypes";
import { MerchCategoryDto, MerchItemDto } from "../api/types/merchStoreTypes";
import { BadgeMessageSystem } from "../api/types/news";
import { NotificationDto } from "../api/types/notifications";
import { CompanyOrgStructureCompanyUserDto } from "../api/types/orgStructureTypes";
import {
  AchievementCoinTransactionDto,
  CategoryEventsCountDto,
} from "../api/types/playerProfileTypes";
import { CompanyColorsDto, Profile } from "../api/types/profile";
import { Role } from "../api/types/rolesTypes";
import { TimeOffPolicyDto } from "../api/types/timeOff/timeOffPolicy";
import {
  User,
  UserListItemDto,
  UserOfSearch,
  UserOfSearchWithActiveStatus,
  UserProfile,
  UserWithRole,
} from "../api/types/usersTypes";
import { TransformedNodeItem } from "../pages/people/orgStructure/orgStructureTypes";
import { DynamicCriteriaOption } from "../pages/settings/group-management/addEditUserGroup/AddEditUserGroupPage";
import {
  itemsWithLoadingState,
  itemsWithPagingState,
} from "../types/stateTypes";

export type AuthState = {
  tokens: {
    accessToken: string;
  } | null;
  profile: Profile | null;
  user: UserProfile | null;
  coinsCount: itemsWithLoadingState<number>;
  lastVisit: Date | null;
};

export type NotificationsState = {
  allNotifications: itemsWithPagingState<NotificationDto[]>;
  unreadNotificationsTotalCount: number;
  uniqueIdNotifications: number[];
};

export type NewsState = {
  messageData: {
    selectedTab: Tabs;
  } & itemsWithPagingState<BadgeMessageSystem[]>;
  essentialsAchievements: itemsWithPagingState<AchievementDto[]>;
  essentialsMerch: itemsWithPagingState<MerchItemDto[]>;
  myTeamEventsByMonth: itemsWithLoadingState<User[]>;
  eventsEndingByMonth: itemsWithLoadingState<AchievementDto[]>;
};

export type AccountState = {
  usersData: itemsWithPagingState<UserListItemDto[]>;
  sorting: {
    sortBy: string;
    sortDirection: SortDirections;
  };
  searchQuery: string;
  colorTheme: CompanyColorsDto | null;
};

export type CompaniesState = {
  searchQuery: string | null;
  companiesData: itemsWithPagingState<Company[]>;
};

export type SystemSettingsState = {
  assignRoleTabState: {
    usersSearchQuery: string | null;
    usersWithRoles: itemsWithPagingState<UserWithRole[]>;
    rolesList: itemsWithLoadingState<Role[]>;
  };
  settingRoleTabState: {
    rolesList: itemsWithLoadingState<Role[]>;
  };
  accessManagementTabState: {
    usersList: itemsWithPagingState<UserWithRole[]>;
    searchQuery: string | null;
    roleIds: number[] | null;
    accessManagementRoles: itemsWithLoadingState<Role[]>;
  };
  addEditRolePageState: {
    companiesList: itemsWithLoadingState<CompanyTreeItem[]>;
    permissionsList: itemsWithLoadingState<string[]>;
    selectedToEditRole: itemsWithLoadingState<Role>;
  };
};

export type ManageBadgesState = {
  badgeGroupsList: itemsWithLoadingState<BadgeGroupManagementDto[]>;
};

export type AnalyticsState = {
  dropdownsData: itemsWithLoadingState<FiltersDataResponse>;
  analyticsData: itemsWithLoadingState<AnalyticsDataResponse>;
  analyticsFilter: AnalyticsRequest;
  popularBadges: itemsWithPagingState<AnalyticsPopularBadgeDto[]>;
};

export type OrgStructureData = {
  tree: TransformedNodeItem;
  users: CompanyOrgStructureCompanyUserDto[];
};

export type OrgStructureState = {
  orgStructureData: itemsWithLoadingState<OrgStructureData>;
};

export type DirectoryState = {
  searchQuery: string;
  location: string;
  country: string;
  directoryUsersList: itemsWithPagingState<UserDirectoryDto[]>;
};

export type MerchStoreState = {
  merchCategoryList: itemsWithLoadingState<MerchCategoryDto[]>;
  merchItemPageData: itemsWithLoadingState<MerchItemDto>;
};

export type MerchDeliveryState = {
  orderStatistics: itemsWithLoadingState<MerchOrdersStatsResponse>;
  ordersData: {
    ordersList: itemsWithPagingState<MerchOrderDto[]>;
    filters: OrdersFilters | null;
    dropdownsData: itemsWithLoadingState<string[]>; // list of departments for filters
  };
};

export type MerchItemsManagementState = {
  coinsStatisticData: itemsWithLoadingState<MerchCoinStatisticsDto>;
  popularMerchItems: {
    selectedTab: PopularMerchItemsTabs;
  } & itemsWithPagingState<PopularMerchItemDto[]>;
  itemsOverTime: {
    itemsOverTimeFilters: {
      dateRange: ClientDateRange;
    };
    itemsOverTimeList: itemsWithPagingState<ItemOverTimeDto[]>;
    itemsOverTimeSorting: {
      sortBy: ItemsOverTimeSortBy;
      sortDirection: SortDirections;
    };
  };
};

export type AchievementsState = {
  achievementsCategories: itemsWithLoadingState<
    AchievementCategoryItemDto[] | null
  >;
  achievementCategory: {
    categoryData: itemsWithLoadingState<AchievementCategoryItemDto>;
    categoryAchievements: itemsWithPagingState<AchievementDto[]>;
    status: AchievementsStatuses | string;
    sortingAchievements: {
      sortBy: AchievementsSortBy | string;
      sortDirection: SortDirections | string;
    };
  };
  achievementPage: {
    achievementDetails: itemsWithLoadingState<AchievementDto>;
    achievementApprovedParticipants: {
      topParticipants: itemsWithLoadingState<UserOfSearch[]>;
      totalCount: number | null;
    };
    achievementParticipants: {
      mode: AchievementParticipantsTabs;
      participantsList: itemsWithPagingState<UserOfSearchWithActiveStatus[]>;
    };
    achievementReactions: itemsWithLoadingState<EmojiReactionUsersItemDto[]>;
  };
  achievementsPage: {
    achievements: itemsWithPagingState<AchievementDto[]>;
    filterCategories: itemsWithLoadingState<AchievementsFilterByCategory[]>;
    status: AchievementsStatuses | string;
    sortingAchievements: {
      sortBy: AchievementsSortBy | string;
      sortDirection: SortDirections | string;
    };
  };
};

export type AchievementsEventsState = {
  achievementEventsDropdownsData: {
    achievementsCategories: itemsWithLoadingState<
      AchievementCategorySearchDto[]
    >;
    achievements: itemsWithLoadingState<AchievementSearchDto[]>;
  };
  achievementsEventsData: {
    achievementsEventsList: itemsWithPagingState<AchievementEventDetailedDto[]>;
    filters: AchievementsEventsFilters | null;
    dateSortDirection: DateSortDirectionAchievementsEvents;
  };
};

export type AchievementsArchiveState = {
  achievementsArchiveData: {
    achievementsArchiveList: itemsWithPagingState<AchievementArchiveListDto[]>;
    filters: AchievementArchiveListFilters | null;
    searchQuery: string | null;
  };
  achievementArchiveData: itemsWithLoadingState<AchievementArchiveItemDto>;
};

export type CompanyCalendarState = {
  calendarData: itemsWithLoadingState<CompanyCalendarDateItem[]>;
  dateRange: ClientDateRange;
  eventType: CompanyCalendarEventType;
  teamDates: itemsWithLoadingState<CompanyCalendarTeamDates>;
  recentAchievements: itemsWithLoadingState<
    CompanyCalendarRecentAchievementDto[]
  >;
};

export type PlayerProfileState = {
  coinsCount: itemsWithLoadingState<number>;
  coinsPraisedCount: itemsWithLoadingState<number>;
  activeAchievementsEvents: itemsWithLoadingState<number>;
  achievementCoinsTransactions: itemsWithLoadingState<
    AchievementCoinTransactionDto[]
  >;
  achievementCategoriesCount: itemsWithLoadingState<CategoryEventsCountDto[]>;
  userAchievementEvents: itemsWithPagingState<AchievementEventDto[]>;
};

export type LeaderboardState = {
  leaderboardData: {
    leaderboardList: itemsWithPagingState<LeaderDto[]>;
    mode: SearchLeadersMode;
  };
  promotedMerchItems: itemsWithLoadingState<MerchItemDto[]>;
  promotedAchievement: itemsWithLoadingState<AchievementDto>;
};

export type PeopleEngagementFilters = {
  dateRange: ClientDateRange;
  engagementStatus: EmployeeEngagementStatuses;
};

export type PeopleOverEventsFilters = {
  dateRange: ClientDateRange;
  tabFilter: PeopleOverEventsMainFilters;
};

export type AchievementsAnalyticsState = {
  categoryAchievementsCountPeople: itemsWithLoadingState<
    CategoryWithParticipantDto[]
  >;
  categoriesWithAchievementsCount: itemsWithLoadingState<
    CategoryWithAchievementsDto[]
  >;
  peopleAchievementsEngagement: {
    filters: PeopleEngagementFilters;
    chartData: itemsWithLoadingState<PeopleEventsEngagementStatsDto[]>;
  };
  achievementsRatingList: itemsWithLoadingState<
    AchievementsParticipantsRatingItemDto[]
  >;
  achievementsInsights: itemsWithLoadingState<AchievementsInsightsDto>;
  achievementsMostActiveUsers: {
    mostActiveUsersFilters: {
      dateRange: ClientDateRange;
    };
    sortingActiveUsers: {
      sortBy: AchievementAnalyticsSortBy;
      sortDirection: SortDirections;
    };
    mostActiveUsersList: itemsWithPagingState<
      AchievementMostActiveUsersItemDto[]
    >;
  };
  peopleOverEvents: {
    peopleOverEventsFilters: PeopleOverEventsFilters;
    peopleOverEventsList: itemsWithPagingState<PeopleOverEventsItemDto[]>;
    sortingPeopleOverEvents: {
      sortBy: AchievementAnalyticsSortBy;
      sortDirection: SortDirections;
    };
  };
};

export type PeopleOverTimeFilters = {
  peopleOverTimeTabFilter: PeopleOverTimeMainFilters;
  dateRange: ClientDateRange;
};

export type ActivityAnalyticsState = {
  activityInsights: itemsWithLoadingState<ActivityInsightsDto>;
  activityMostActiveUsers: {
    sortingActiveUsers: {
      sortBy: ActivityAnalyticsActiveUsersSortBy;
      sortDirection: SortDirections;
    };
    activityMostActiveUsersFilters: {
      dateRange: ClientDateRange;
    };
    activityMostActiveUsersList: itemsWithPagingState<
      ActivityMostActiveUsersItemDto[]
    >;
  };
  peopleOverTime: {
    peopleOverTimeFilters: {
      peopleOverTimeTabFilter: PeopleOverTimeMainFilters;
      dateRange: ClientDateRange;
    };
    peopleOverTimeList: itemsWithPagingState<PeopleOverTimeItemDto[]>;
    sortingPeopleOverTime: {
      sortBy: ActivityAnalyticsPeopleOverTimeSortBy;
      sortDirection: SortDirections;
    };
  };
};
export type EmployeePageState = {
  userData: itemsWithLoadingState<UserProfile>;
  employeeData: itemsWithLoadingState<UserCardDto>;
  positionsData: itemsWithLoadingState<string[]>;
  departmentsData: itemsWithLoadingState<string[]>;
  usersStructureData: {
    filterBy: string[] | null;
    userId: number | null;
    usersList: itemsWithLoadingState<User[]>;
  };
};

export type EmployeeDeliveryInfoState = {
  employeeMerchOrders: {
    ordersList: itemsWithPagingState<ExchangeHistoryItemDto[]>;
    sorting: {
      sortBy: EmployeeOrdersSortBy;
      sortDirection: SortDirections;
    };
  };
  employeeReceivedItems: itemsWithPagingState<MerchItemDto[]>;
};

export type EmployeeAchievementsState = {
  profileMessages: itemsWithPagingState<BadgeMessageSystem[]>;
  feedbackRequestsData: itemsWithLoadingState<Feedback[]>;
  userBadgesData: itemsWithPagingState<UserBadgeDto[]>;
  totalBadgesCount: itemsWithLoadingState<number>;
  unresponsedFeedbackRequestsData: itemsWithLoadingState<
    UnresponsedFeedbackRequest[]
  >;
};

export type ManageAutoEventsState = {
  autoEventsList: itemsWithLoadingState<AutoCoinEventListItemDto[]>;
};
export enum Tabs {
  all = "all",
  myTeam = "myTeam",
}

export type TPlayerProfilePermissions = {
  isViewCoinsBalance: boolean;
  isViewPraisedCoins: boolean;
  isViewActivityChart: boolean;
  isViewQuestBacklog: boolean;
};

export type TUserTimeOffProfilePermissions = {
  isViewTimeOffBalances: boolean;
  isViewTimeOffBacklog: boolean;
};

export type UsersDirectoryPayload = {
  search?: string;
  country?: string;
  location?: string;
};

export type GroupManagementState = {
  searchQueryUserGroup: string | null;
  userGroupsData: itemsWithLoadingState<UserGroupDto[]>;
  userGroupData: itemsWithLoadingState<UserGroupWithParticipantsDto>;
  groupsByUserData: itemsWithLoadingState<UserGroupDto[]>;
  groupsWithoutUserData: itemsWithLoadingState<UserGroupDto[]>;
  dynamicCriteriaData: itemsWithLoadingState<
    Record<GroupFilterType, DynamicCriteriaOption[]>
  >;
};

export type TimeOffPolicyTypesState = {
  timeOffPolicyTypesList: itemsWithLoadingState<TimeOffPolicyTypeListItemDto[]>;
  timeOffPolicyType: itemsWithLoadingState<TimeOffPolicyTypeDto>;
  timeOffPolicies: {
    isEnabledStatus: null | boolean;
    policiesList: itemsWithLoadingState<TimeOffPolicyListItemDto[]>;
  };
};

export type TimeOffPoliciesState = {
  timeOffPolicy: itemsWithLoadingState<TimeOffPolicyDto>;
  timeOffLocationsData: itemsWithLoadingState<TimeOffLocationFilter[]>;
  timeOffPolicyEmployees: {
    sortingEmployeesList: {
      sortBy: TimeOffPolicyAssignPeopleSortBy;
      sortDirection: SortDirections;
    };
    employeesList: itemsWithPagingState<TimeOffPolicyUserDto[]>;
    filters: TimeOffPolicyAssignPeople;
    searchQuery: string;
  };
  rolesList: itemsWithLoadingState<Role[]>;
  positions: itemsWithLoadingState<TimeOffPolicyPositionsRequest>;
};

export type TimeOffProfileState = {
  currentBalances: itemsWithLoadingState<UserTimeOffBalanceListItemDto[]>;
  timeOffBacklog: {
    timeOffBacklogList: itemsWithPagingState<TimeOffRequestListItemDto[]>;
    timeOffBacklogSorting: {
      sortBy: UserTimeOffBacklogSortBy;
      sortDirection: SortDirections;
    };
  };
  requestData: itemsWithLoadingState<UserTimeOffRequestDto>;
};

export type TimeOffCalendarState = {
  monthRange: [string, string];
  searchUserQuery: string | null;
  customFilters: CustomSearchedFilters | null;
  timeOffsData: itemsWithLoadingState<TimeOffCalendarUserListItemDto[]> & {
    page: number;
    totalItems: number;
  };
};

export type TimeOffRequestsState = {
  requestsData: itemsWithPagingState<TimeOffRequestsManagementListItemDto[]>;
  requestsFilters: {
    status: TimeOffRequestsStatusFilter;
    filters: CustomFilters;
  };
};
